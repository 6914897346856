<template>
  <div>
    <general-table
      ref="zonesTable"
      :api-url="apiUrl"
      :add-type="addType"
      :columns="columns"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :viw-component="viwComponent"
      :view-content="false"
      :type="type"
      :selectable="false"
    >
      <template #filter>
        <b-col
          cols="12"
          md="3"
        >
          <b-input-group>
            <b-form-select
              v-model="filter.country_id"
              value-field="id"
              text-field="name"
              :options="countries"
            >
              <template #first>
                <b-form-select-option
                  disabled
                >
                  Country
                </b-form-select-option>
              </template>
            </b-form-select>

            <b-input-group-append v-if="filter.country_id">
              <b-button
                size="sm"
                @click="filter.country_id = ''"
              >
                <span class="lead">
                  &times;
                </span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-button
          variant="primary"
          :disabled="Object.values(filter).length < 1"
          @click="$refs.zonesTable.getAllData()"
        >
          Filter
        </b-button>

      </template>
    </general-table>

  </div>
</template>

<script>
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/zones',
      addType: 'Add New Zone',
      type: 'page',
      viewContent: false,
      filter: {},
      countries: '',
      types: '',
      addComponentName: 'add-shipping-zones',
      editComponent: 'edit-shipping-zones',
      viwComponent: 'show-shipping-zones',
      columns: [
        { key: 'name_ar', label: 'Arabic Name' },
        { key: 'name_en', label: 'English Name' },
        { key: 'country_name', label: 'Country' },
        { key: 'price', label: 'Price' },
        { key: 'delivery_days', label: 'Delivery days' },

        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  computed: {

    apiUrl() {
      let base = 'admin/zones?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
  },
  mounted() {
    this.getCountries()
  },
  methods: {

    async getCountries() {
      await axios.get('admin/countries').then(res => {
        if (res.status === 200) {
          this.countries = res.data?.data.countries
        }
      })
    },
    filterTypes(type) {
      const filteredType = this.countries.filter(item => item.text !== type)
      this.types = filteredType
    },
  },

}
</script>

<style>

</style>
